import React, { useState, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

import { Redirect, useParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { currency } from "../../utils/formatter";
import AppLoading from "../../components/AppLoading";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import secureLogo from "../../images/seal_secure_id.png";
import encryptedLogo from "../../images/seal_encrypted.png";
//import poster from "../../images/poster_odoj.png";
import userAvatar from "../../images/default.png";
import { useDocument } from "react-firebase-hooks/firestore";
import uniqid from "uniqid";

import {
  firestore,
  FieldValue,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";

function Daftar(props) {
  const { location } = props;
  const classes = useStyles();

  const [form, setForm] = useState({
    email: "",
    nama: "",
    wa: "",
  });

  let env = "DEV";
  if (process.env.REACT_APP_ENV === "PROD") {
    env = "PROD";
  }

  const [referal, setReferal] = useState({
    id: JSON.parse(localStorage.getItem('ref')) || '',
    nama: ""
  })

  const [prod, setProd] = useState({
    produk_id:"1",
    nama_produk:"",
    harga_produk:"",
    komisi_affiliate:"",
    features:[],
    testimony:[]
  })

  const [error, setError] = useState({
    email: "",
    nama: "",
    wa: "",
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [orderId, setOrderid] = useState("");
  //const [namaRef, setNamaref]  = useState("");
  const { user, loading } = useFirebase();
  const {slug} = useParams();
  //hardcode referal untuk subdomain 'ODOJ'
  //const odojId = env === 'DEV' ? 'LwqeBdQwPQsJlBe8Zt9f' : 'atPUo5HEpv7ACmonUI6B'
  const [ ref, produk_id ] = slug?.split("&") ? slug?.split("&") : (JSON.parse(localStorage.getItem('ref') !== null) ? [`ref=${JSON.parse(localStorage.getItem('ref'))}` , "produk_id=1"] : [`ref=1`, "produk_id=1"]) ;
  
  const userDoc = firestore.doc(`user/${ref?.split("=")[1]}`);
  const [snapshotUserDoc, userLoading] = useDocument(userDoc);

  useEffect(() => {
    if (snapshotUserDoc?.data()?.nama) {
      localStorage.setItem("ref", JSON.stringify(ref?.split("=")[1]));
      localStorage.setItem(
        "namaRef",
        JSON.stringify(snapshotUserDoc.data()?.nama)
      );
      setReferal({
        id: ref?.split("=")[1],
        nama: JSON.parse(localStorage.getItem("namaRef")),
      });
    } else if (JSON.parse(localStorage.getItem("ref"))) {
      setReferal({
        id: JSON.parse(localStorage.getItem("ref")),
        nama: JSON.parse(localStorage.getItem("namaRef")),
      });
    }
  }, [snapshotUserDoc, ref]);

  async function getProductDetail() {
    const produkDoc = await firestore
      .collection(`produk`)
      .where("produk_id", "==", produk_id?.split("=")[1])
      .get();
    if (produkDoc.docs) {
      setProd({
        produk_id: produk_id?.split("=")[1],
        ...produkDoc.docs[0].data(),
      });
    }
  }

  useEffect(() => {
    getProductDetail();
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    const newError = { ...error };

    if (!form.email) {
      newError.email = "Email Wajib Diisi";
    } else if (!isEmail(form.email)) {
      newError.email = "Email Tidak Valid";
    }

    if (!form.nama) {
      newError.nama = "Nama Wajib Diisi";
    }

    if (!form.wa) {
      newError.wa = "Nomor Whatsapp Wajib Diisi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findErrors = validate();

    async function createOrder() {
      //write ke coll order
      const noTransaksi = uniqid();
      const docId = noTransaksi;
      await firestore.doc(`transaksi/${docId}`).set({
          // created_at: FieldValue.serverTimestamp(),
          // email: form?.email,
          // nama: form?.nama,
          // wa: form?.wa,
          // status: "pending",
          // ref: referal.id ? referal.id : "",
          // produk_id: prod.produk_id,
          user_id: 'n/a',
          nama: form?.nama ?? "",
          username: 'n/a',
          no_hp: form?.wa ?? "",
          email: form?.email,
          no_transaksi: `${noTransaksi}`,
          jumlah_coin: prod.topup_kredit,
          status: "pending",
          tanggal: Date.now(),
          jumlah_transfer: prod.harga_produk,
          doc_id: docId,
          ref: referal.id ? referal.id : "",
          produk_id: prod.produk_id,
        })
        .catch((error) => {
          console.log("Error adding document:", error);
        });

      return docId;
    }

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        const docId = await createOrder();
        //functions.useEmulator('localhost', 8000);
        const payment = functions.httpsCallable("payment-topup");
        const response = await payment({
          nama: form?.nama,
          email: form?.email,
          no_hp: form?.wa,
          no_transaksi: docId,
          doc_id: docId,
          user_id: 'n/a'
        });

        const data = response.data;

        if (data?.invoice_url !== "") {
          window.open(data?.invoice_url, "_self");
        }
      } catch (e) {
        const newError = {};
        console.log(e);
        switch (e.code) {
          case "auth/invalid-email":
            newError.email = "Email Tidak Valid";
            break;
          case "auth/user-disable":
            newError.email = "Pengguna Diblokir";
            break;
          default:
            newError.email = "Terjadi Kesalahan, Silahkan Coba Lagi";
            break;
        }
        setError(newError);
        setSubmitting(false);
      }
    }
  };

  if (loading) {
    return <AppLoading />;
  }

  // if (user) {
  //   const redirectTo =
  //     location.state && location.state.from && location.state.from.pathname
  //       ? location.state.from.pathname
  //       : "/";
  //   return <Redirect to={redirectTo} />;
  // }

  return (
    <>
      <Helmet>
        <title>Daftar | Karir.ai</title>
      </Helmet>
      <Container maxWidth="md">
          {/* <img className={classes.secured} src={secureLogo} alt="" />
          <img className={classes.secured} src={encryptedLogo} alt="" /> */}
          <div className={classes.logoWrap}>
            <Logo />
          </div>
        <Paper className={classes.paper}>
          <div className={classes.paperInner}>
            <div className={classes.paperMobile}>
              <h3>Yang kamu dapatkan</h3>
              <div className={classes.benefitList}>
                <ul>
                  {prod.features.map((feature)=>{
                    return <>
                    <li>{feature}</li>
                    </>
                  })}
                </ul>
              </div>
              <div className={classes.orderDetail}>
                <h5>RINCIAN PESANAN:</h5>
                <div className={classes.order}>
                  <div className={classes.productName}>{prod.nama_produk}</div>
                  <div className={classes.productPrice}><s>{currency(prod.harga_produk_beforediskon ? prod.harga_produk_beforediskon : 0)}</s><br /> {currency(prod.harga_produk)} </div>
                </div>
                <div className={classes.order}>
                  <div className={classes.productName}>Biaya Admin</div>
                  <div className={classes.productPrice}>Rp.  2,500</div>
                </div>
                {/* <div className={classes.uniqueCode}>
                  <div className={classes.productName}>PPN</div>
                  <div className={classes.productPrice}>Rp. 500</div>
                </div> */}
                <div className={classes.TotalPrice}>
                  <div className={classes.TotalPriceLabel}>Total</div>
                  <div className={classes.productPrice}>{currency(prod.harga_produk + 2500)}</div>
                </div>
              </div>
            </div>
            <div className={classes.paperLeft}>
              <div className={classes.paperTop}>
                <img className={classes.secured} src={secureLogo} alt="" />
                <img className={classes.secured} src={encryptedLogo} alt="" />
              </div>
              <div className={classes.greetings}>
                <Typography className={classes.pageTitle}>Isi form dibawah ini</Typography>
                {/* <Typography className={classes.pageSubtitle}>
                  Isi form dibawah ini untuk melanjutkan
                </Typography> */}
              </div>
              {isSubmitting ? <AppLoading /> : 
              <form className={classes.formReg} onSubmit={handleSubmit} noValidate>
                <TextField
                  id="nama"
                  type="text"
                  name="nama"
                  margin="normal"
                  label="Nama Lengkap"
                  fullWidth
                  required
                  value={form.nama}
                  onChange={handleChange}
                  helperText={error.nama}
                  error={error.nama ? true : false}
                  disabled={isSubmitting}
                />
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  margin="normal"
                  label="Alamat Email"
                  fullWidth
                  required
                  value={form.email}
                  onChange={handleChange}
                  helperText={error.email}
                  error={error.email ? true : false}
                  disabled={isSubmitting}
                  />
                  <TextField
                    id="wa"
                    type="text"
                    name="wa"
                    margin="normal"
                    label="Nomor Whatsapp Aktif"
                    fullWidth
                    required
                    value={form.wa}
                    onChange={handleChange}
                    helperText={error.wa}
                    error={error.wa ? true : false}
                    disabled={isSubmitting}
                  />

                  <Grid container className={classes.buttons}>
                    <Grid item xs>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        Klik Disini Untuk Melanjutkan
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              }
            </div>
            <div className={classes.paperRight}>
              <h3>Yang kamu dapatkan</h3>
              <div className={classes.benefitList}>
                <ul>
                  {prod.features.map((feature) => {
                    return (
                      <>
                        <li>{feature}</li>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className={classes.orderDetail}>
                <h5>RINCIAN PESANAN:</h5>
                <div className={classes.order}>
                  <div className={classes.productName}>{prod.nama_produk}</div>
                  <div className={classes.productPrice}>
                    <s>
                      {currency(
                        prod.harga_produk_beforediskon
                          ? prod.harga_produk_beforediskon
                          : 0
                      )}
                    </s>
                    <br /> {currency(prod.harga_produk)}{" "}
                  </div>
                </div>
                <div className={classes.order}>
                  <div className={classes.productName}>Biaya Admin</div>
                  <div className={classes.productPrice}>Rp.  2,500</div>
                </div>
                {/* <div className={classes.uniqueCode}>
                  <div className={classes.productName}>PPN</div>
                  <div className={classes.productPrice}>Rp. 500</div>
                </div> */}
                <div className={classes.TotalPrice}>
                  <div className={classes.TotalPriceLabel}>Total</div>
                  <div className={classes.productPrice}>{currency(prod.harga_produk + 2500)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.paperBot}>
            <div className={classes.testimoniesBox}>
              {prod?.testimony ? <>
              <div className={classes.testimony}>
                <img className={classes.avatar} src={prod?.testimony[0]?.photo ? prod?.testimony[0]?.photo : userAvatar} alt="" />
                <div className={classes.testimonyContent}>
                  <h4 className={classes.avatarName}>{prod?.testimony[0]?.name}</h4>
                  <p>{prod?.testimony[0]?.comment}</p>
                </div>
              </div>
              </> : ""}
              {prod?.testimony ? <>
              <div className={classes.testimony}>
                <img className={classes.avatar} src={prod?.testimony[1]?.photo ? prod?.testimony[1]?.photo : userAvatar} alt="" />
                <div className={classes.testimonyContent}>
                  <h4 className={classes.avatarName}>{prod?.testimony[1]?.name}</h4>
                  <p>{prod?.testimony[1]?.comment}</p>
                </div>
              </div>
              </> : ""}
            </div>
          </div>
        </Paper>
        {referal.nama !== "" ? (
          <p>
            Anda direferensikan oleh : {referal.nama} (ID: {referal.id}){" "}
          </p>
        ) : (
          ""
        )}
      </Container>
    </>
  );
}

export default Daftar;
