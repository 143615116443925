import React, { useState } from "react";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

import { Redirect } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { auth, useFirebase, functions } from "../../components/FirebaseProvider";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import AppLoading from "../../components/AppLoading";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";

function Resend(props) {
  const { location } = props;
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [form, setForm] = useState({
    email: ""
  });

  const [error, setError] = useState({
    email: ""
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const { user, loading } = useFirebase();

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    const newError = { ...error };

    if (!form.email) {
      newError.email = "Email Wajib Diisi";
    } else if (!isEmail(form.email)) {
      newError.email = "Email Tidak Valid";
    }
    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        //await auth.signInWithEmailAndPassword(form.email, form.password);
        const resendPassword = functions.httpsCallable("user-resendPass");
        const response = await resendPassword({
          email: form?.email
        });

        const data = response.data;
        setOpenDialog(true);
         setSubmitting(false);

      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "not-found":
            newError.email = "Email Tidak Ditemukan Di Data Pelanggan";
            break;
          default:
            newError.email = "Terjadi Kesalahan, Silahkan Coba Lagi";
            break;
        }
        setError(newError);
        setSubmitting(false);
      }
    }
  };

  if (loading) {
    return <AppLoading />;
  }

  return (
    <>
      <Helmet>
        <title>Login | Teladan Digital</title>
      </Helmet>
      <Container maxWidth="xs">
        <Paper className={classes.paper}>
          <div style={{ display: "flex", justifyItems: "center" }}>
            <Logo />
          </div>
          <Typography className={classes.pageTitle}>Selamat Datang,</Typography>
          <Typography className={classes.pageSubtitle}>
            Masukan email yang digunakan saat pembelian tiket
          </Typography>

          <form onSubmit={handleSubmit} noValidate>
            <TextField
              id="email"
              type="email"
              name="email"
              margin="normal"
              label="Alamat Email"
              fullWidth
              required
              value={form.email}
              onChange={handleChange}
              helperText={error.email}
              error={error.email ? true : false}
              disabled={isSubmitting}
            />
        

            <Grid container className={classes.buttons}>
              <Grid item xs>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Kirim Ulang Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Typography className={classes.pageSubtitle}>
        <div>Belum punya tiket? Beli tiket <a target="_blank" href="/daftar">klik disini.</a> </div>
        <div><p><a target="_blank" href="/login">Login</a> | <a target="_blank" href="https://wa.me/6281770558516?text=halo kak, saya perlu bantuan...">Hubungi CS</a>. </p></div>
        </Typography>
      </Container>

{/* Dialog Box Logout */}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.centerText}>
              Password berhasil dikirim ke email. Silahkan Cek Inbox / Spam folder.
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default Resend;
