import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import teladandigital from "../../images/logo-full.svg";

const Logo = (props) => {
  const { classes } = props;

  let logo = teladandigital;

  return <img className={classes.mainLogo} src={logo} alt="" />;
};

export default withStyles(styles)(Logo);
